const v1_my_order_icon: React.FC<React.SVGProps<SVGSVGElement>> = (props: any) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
			<mask
				id='mask0_1246_19001'
				style={{ maskType: 'alpha' }}
				maskUnits='userSpaceOnUse'
				x='0'
				y='0'
				width='24'
				height='24'>
				<rect width='24' height='24' fill='#D9D9D9' />
			</mask>
			<g mask='url(#mask0_1246_19001)'>
				<path d='M3.2325 2.28594V1.6726H2.39917V0.00593518H9.89917V1.6726H9.06583V2.28594C9.06583 2.8676 9.185 3.43343 9.42 3.96677L10.1667 5.66093C10.3117 5.9901 10.4208 6.32927 10.5108 6.6726H1.78833C1.87833 6.32927 1.98667 5.9901 2.1325 5.66093L2.87917 3.96677C3.11339 3.4374 3.2332 2.86481 3.2325 2.28594ZM19.7983 8.33927L18.3292 17.8859C18.2412 18.4774 17.9429 19.0173 17.4891 19.4067C17.0353 19.7961 16.4563 20.0088 15.8583 20.0059H3.94C3.34191 20.0088 2.76283 19.7959 2.30899 19.4064C1.85515 19.0168 1.55698 18.4767 1.46917 17.8851L0 8.33927H19.7983ZM6.56583 11.6726H4.89917V16.6726H6.56583V11.6726ZM10.7325 11.6726H9.06583V16.6726H10.7325V11.6726ZM14.8992 11.6726H13.2325V16.6726H14.8992V11.6726ZM18.2167 6.6726C19.0317 4.51344 19.2833 2.57594 18.8483 1.39594C18.5767 0.656768 18.1008 0.320102 17.7492 0.167602C16.3867 -0.424065 14.7533 0.613435 13.2992 2.2951L15.7333 3.75594L14.8758 5.1851L12.2783 3.62594C11.8143 4.29786 11.3932 4.99842 11.0175 5.72344L10.9533 5.84677C10.8533 6.0401 10.7 6.3351 10.53 6.67177H18.2183L18.2167 6.6726Z' />
			</g>
		</svg>
	);
};
export default v1_my_order_icon;
