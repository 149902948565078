const v1_rider_icon: React.FC<React.SVGProps<SVGSVGElement>> = (props: any) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'>
			<mask
				id='mask0_1246_19001'
				style={{ maskType: 'alpha' }}
				maskUnits='userSpaceOnUse'
				x='0'
				y='0'
				width='24'
				height='24'>
				<rect width='24' height='24' fill='#D9D9D9' />
			</mask>
			<g mask='url(#mask0_1246_19001)'>
				<path
					d='M8 7C8 8.06087 8.42143 9.07828 9.17157 9.82843C9.92172 10.5786 10.9391 11 12 11C13.0609 11 14.0783 10.5786 14.8284 9.82843C15.5786 9.07828 16 8.06087 16 7C16 5.93913 15.5786 4.92172 14.8284 4.17157C14.0783 3.42143 13.0609 3 12 3C10.9391 3 9.92172 3.42143 9.17157 4.17157C8.42143 4.92172 8 5.93913 8 7Z'
					stroke='#718096'
					stroke-width='2'
					stroke-linecap='round'
					stroke-linejoin='round'
				/>
				<path
					d='M6 21V19C6 17.9391 6.42143 16.9217 7.17157 16.1716C7.92172 15.4214 8.93913 15 10 15H10.5'
					stroke='#718096'
					stroke-width='2'
					stroke-linecap='round'
					stroke-linejoin='round'
				/>
				<path
					d='M17.8002 20.817L15.6282 21.955C15.5638 21.9885 15.4913 22.0035 15.4188 21.9982C15.3463 21.9929 15.2767 21.9676 15.2178 21.9251C15.1589 21.8826 15.113 21.8245 15.0851 21.7574C15.0573 21.6903 15.0487 21.6167 15.0602 21.545L15.4752 19.134L13.7182 17.427C13.6658 17.3763 13.6287 17.3119 13.6111 17.2411C13.5935 17.1703 13.5962 17.096 13.6188 17.0266C13.6414 16.9573 13.6831 16.8957 13.7391 16.8489C13.795 16.8021 13.863 16.772 13.9352 16.762L16.3632 16.41L17.4492 14.217C17.4817 14.1517 17.5318 14.0967 17.5938 14.0583C17.6558 14.0199 17.7273 13.9995 17.8002 13.9995C17.8732 13.9995 17.9447 14.0199 18.0067 14.0583C18.0687 14.0967 18.1188 14.1517 18.1512 14.217L19.2372 16.41L21.6652 16.762C21.7373 16.7723 21.805 16.8027 21.8607 16.8495C21.9164 16.8963 21.9579 16.9578 21.9805 17.027C22.003 17.0962 22.0058 17.1703 21.9885 17.241C21.9711 17.3117 21.9343 17.3761 21.8822 17.427L20.1252 19.134L20.5392 21.544C20.5517 21.6158 20.5437 21.6898 20.5162 21.7573C20.4887 21.8249 20.4429 21.8834 20.3838 21.9262C20.3248 21.969 20.2549 21.9944 20.1822 21.9995C20.1094 22.0046 20.0367 21.9891 19.9722 21.955L17.8002 20.817Z'
					stroke='#718096'
					stroke-width='2'
					stroke-linecap='round'
					stroke-linejoin='round'
				/>
			</g>
		</svg>
	);
};
export default v1_rider_icon;
